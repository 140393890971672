import React from "react"
import { convertBackToActualPrice, EURO } from "./common"
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isRTL } from './common'
import { useTranslation } from "react-i18next"

const Item = (props) => {
  const { item, isRefund, currencyConfig, receipt, discountsApplied } = props
  const { t, i18n } = useTranslation()
  const currentLocale = i18n.language;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  let name = item.receiptName ? item.receiptName : item.name
  let arabicName = null
  const PERCENTAGE = "percentage"
  const regExp = /[\u0600-\u06FF]/g;
  if (regExp.test(item.name)) {
    const splitName = item.name.split(")");
    const englishText = splitName[0].replace("(", "");
    arabicName = splitName[1];
    name = englishText;
  }

  console.log("Item render", item)

  let itemTotalPrice = 0
  if (!isRefund && !isNaN(item.base_price) && !isNaN(item.quantity)) {
    itemTotalPrice = item.total_price.reduce((a, b) => a + b, 0)
    if (isNaN(item.crv)) {
      itemTotalPrice = item.quantity * item.base_price
    } else {
      itemTotalPrice = item.quantity * (item.crv + item.base_price)
    }
    if ((receipt.receiptFormat && receipt.receiptFormat === EURO) || (!receipt.isGrossAmountPresent && receipt.taxes === "inclusive")) {
      let itemTotalTax = 0
      const itemTax = item.tax
      if (Array.isArray(itemTax) && itemTax.length > 0) {
        if (itemTax.every(val => typeof val === 'number')) {
          itemTotalTax = itemTax.reduce((acc, val) => acc + val, 0);
        } else {
          itemTotalTax = Object.values(itemTax[0]).reduce((acc, arr) => acc + arr.reduce((sum, val) => sum + val, 0), 0);
        }
      }
      itemTotalPrice = itemTotalPrice + itemTotalTax
    }
  } else if (isRefund && item.amount) {
    itemTotalPrice = item.amount
  }
  return (
    <div style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
      {!isRefund && (
        <span
          style={{
            alignSelf: "flex-start",
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {Array.isArray(item.images) && item.images[0] && item.images[0].url && (
            <img style={{ maxHeight: 50, maxWidth: 50 }} src={item.images[0].url} alt="item" />
          )}
        </span>
      )}
      {isRefund ?
        (
          <span style={{ flexDirection: "column", flexGrow: 2, display: "flex" }}>
            <span
              style={{
                alignItems: "flex-start",
                flexGrow: 2,
                paddingRight: isRTL(currentLocale) ? 5 : 20,
                paddingLeft: 5,
                wordWrap: isMobile && 'break-word',
                width: isMobile && '100px'
              }}
            >
              {name}<br />
              {item.quantity} {t('items')}
            </span>
            {arabicName &&
              <span
                style={{
                  alignSelf: "flex-end",
                  flexGrow: 2,
                  paddingRight: 5,
                  paddingLeft: 20,
                  wordWrap: isMobile && 'break-word',
                  width: isMobile && '100px'
                }}>{arabicName}
              </span>
            }
          </span>
        ) : (
          <div style={{ flexDirection: "column", flexGrow: 2, display: "flex" }}>
            <span
              style={{
                alignItems: "flex-start",
                flexGrow: 2,
                wordWrap: isMobile && 'break-word',
              }}
            >
              <span style={{ marginRight: isRTL(currentLocale) ? 0 : 100, marginLeft: isRTL(currentLocale) ? 100 : 0, width: "50%" }}>{name}</span>
              <br />
              <div style={{ marginBottom: '10px', marginLeft: isRTL(currentLocale) ? 0 : '20px', marginRight: isRTL(currentLocale) ? '20px' : 0 }}>
                {((receipt.receiptFormat && receipt.receiptFormat === EURO) || (!receipt.isGrossAmountPresent && receipt.taxes === "inclusive")) ? (
                  `${item.quantity} ${t('items')}`
                ) : (
                  `${item.quantity} @${convertBackToActualPrice(item.base_price + item.crv, currencyConfig, true)}`
                )}
                <span style={{ float: isRTL(currentLocale) ? "left" : "right" }}>{convertBackToActualPrice(itemTotalPrice, currencyConfig, true).toString()}</span>
                {Array.isArray(discountsApplied) && discountsApplied.length > 0 &&
                  <>
                    {(discountsApplied).map((discount) => {
                      return (
                        <div style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          flexWrap: "wrap"
                        }}>
                          <span style={{ flex: 2 }}>{discount.code}</span>
                          <span style={{ flex: 1, textAlign: "end" }}>-{discount.amount}</span>
                        </div>)
                    })}
                  </>
                }
                {Array.isArray(item.taxes) && item.taxes.length > 0 &&
                  <>
                    {(item.taxes).map((obj, i) => {
                      const key = Object.keys(obj).find(key => key !== "type" && key !== "name");
                      const percentageValue = item.taxes[i].type === PERCENTAGE ? item.taxes[i][key] + "%" : null
                      const amount = Array.isArray(item.tax) ? item.tax.reduce((acc, taxItem) => {
                        if (taxItem[key]) {
                          return acc + taxItem[key].reduce((innerAcc, val) => innerAcc + val, 0);
                        }
                        return acc;
                      }, 0) : 0
                      return (
                        <div>
                          {amount > 0 &&
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "wrap"
                            }}>
                              <span style={{ flex: 2 }}>{receipt.taxes === "inclusive" && receipt.isGrossAmountPresent && `(${t('included')}`} {item.taxes[i].name === "Sales tax" ? t('salesTax') : item.taxes[i].name} {percentageValue}</span>
                              <span style={{ flex: 1, textAlign: "end" }}>{convertBackToActualPrice(amount, currencyConfig, true)}{receipt.taxes === "inclusive" && receipt.isGrossAmountPresent && ")"}</span>
                            </div>
                          }
                        </div>)
                    })}
                  </>
                }
              </div>
            </span>
            {arabicName &&
              <span
                style={{
                  alignSelf: "flex-end",
                  flexGrow: 2,
                  paddingRight: 5,
                  paddingLeft: 20,
                  wordWrap: isMobile && 'break-word',
                  width: isMobile && '100px'
                }}
              >
                {arabicName}
              </span>
            }
          </div>
        )}
    </div>
  )
}

export default Item
