import React, { useState, useEffect } from "react"
import InlineEdit from "@atlaskit/inline-edit"
import styled from "styled-components"
import { gridSize, fontSize } from "@atlaskit/theme"
import Textfield from "@atlaskit/textfield"
import ReceiptsList from "./ReceiptsList"
import ZippinApp from "./ZippinApp"
import { format } from "date-fns"
import moment from "moment"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "@material-ui/icons/Info"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import * as env from "./env"
import ExpiryDateInput from "./ExpiryDateInput";
import './App.css';
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { EURO, isRTL } from "./common"
import { Grid } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import TimePopUp from "./TimePopUp"
import { useTranslation, Trans } from "react-i18next"

const styles = () => ({
  tooltip: {
    backgroundColor: "#fff",
    border: '1px solid grey',
    lineHeight: "1em",
    fontSize: "0.875rem",
    marginLeft: 10
  },
  fullOpacity: {
    opacity: 1,
  },
  arrow: {
    color: "#fff",
    "&::before": {
      backgroundColor: "#fff",
      border: "1px solid grey"
    }
  },
  container: {
    justifyContent: 'center',
    marginTop: 10,
  },
  button: {
    backgroundColor: "#9B28C4",
    color: "white",
  },
  label: {
    alignSelf: 'center',
    paddingRight: '10px',
    whiteSpace: 'nowrap'
  },
  textFieldBackGroundColor: {
    backgroundColor: 'rgb(244, 245, 247)',
  },
  textValueFont: {
    fontSize: 14,
    paddingLeft: 6,
  },
  fontFamily: {
    fontFamily: env.getReceiptUIType() === "brightline" ? "Futura PT Bold" : 'Duplet',
  },
  buttonItem: {
    marginTop: '0px',
    width: '100%',
    '@media (max-width: 280px)': {
      marginTop: '8px'
    },
    '@media (max-width: 1523px)': {
      marginTop: '8px'
    },
  },
  element2ToHide: {
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  element1ToHide: {
    '@media (min-width: 600px)': {
      display: 'none'
    },
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
})

const ReadViewContainer = styled.div`
  display: flex;
  font-size: ${fontSize()}px;
  line-height: ${(gridSize() * 3) / fontSize()};
  max-width: 100%;
  min-height: ${(gridSize() * 3) / fontSize()}em;
  padding: ${gridSize()}px ${gridSize() - 2}px;
  word-break: break-word;
  background-color: rgb(244, 245, 247);
`

function is_touch_device() {
  try {
    let prefixes = " -webkit- -moz- -o- -ms- ".split(" ")

    let mq = function (query) {
      return window.matchMedia(query).matches
    }

    if (
      "ontouchstart" in window ||
      (typeof window.DocumentTouch !== "undefined" && document instanceof window.DocumentTouch)
    ) {
      return true
    }

    return mq(["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(""))
  } catch (e) {
    console.error("(Touch detect failed)", e)
    return false
  }
}

const Receipts = (props) => {
  const { classes } = props
  let filteredCountry = ''
  const { t, i18n } = useTranslation()
  if (env.getReceiptUICountry() !== undefined) {
    const country = props.countries.filter(item => item.name === env.getReceiptUICountry());
    filteredCountry = Array.isArray(country) && country.length > 0 ? country[0].id : ''
  }
  const countryName = env.getReceiptUICountry() !== undefined ? env.getReceiptUICountry() : props.searchParams.get('country_name')
  const id = env.getReceiptUICountry() !== undefined ?
    filteredCountry : Array.isArray(props.country) && props.country.length > 0 ? props.country[0].id : ''
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const formattedDate = moment().format("MM/DD/YYYY");
  const [receipts, setReceipts] = useState([])
  const [country, setCountry] = useState(countryName)
  const [countryId, setCountryId] = useState(id)
  const [transactionId, setTransactionId] = useState("")
  const [amount, setAmount] = useState("")
  const [dateTime, setDateTime] = useState(formattedDate);
  const [last4CC, setLast4CC] = useState("")
  const [cardExpirationMonth, setCardExpirationMonth] = useState("")
  const [cardExpirationYear, setCardExpirationYear] = useState("")
  const [defaultDateTime, setDefaultDateTime] = useState(formattedDate)
  let [open, setOpen] = useState(false)
  let [expiryTooltipOpen, setExpiryTooltipOpen] = useState(false)
  let [transactionIdTooltipOpen, setTransactionIdTooltipOpen] = useState(false)
  let [amountToolTipOpen, setAmountTooltipOpen] = useState(false)
  const [expiryDate, setExpiryDate] = useState({});
  const [showNoReceiptFound, setShowNoReceiptFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [last4Error, setLast4Error] = useState("");
  const [dateError, setDateError] = useState("");
  const [showTransactionId, setShowTransactionId] = useState(false);
  const [showAmount, setShowAmount] = useState(false);
  const [showTimePopUp, setTimePopUp] = useState(false)
  const [receiptData, setReceiptData] = useState([])
  const [expiryDateError, setExpiryDateError] = useState("")
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [noReceiptFoundMessage, setNoReceiptFoundMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [inputValues, setInputValues] = useState("");
  const [time, setTime] = useState(null);
  const currentLocale = localStorage.getItem("currentLocale") ? JSON.parse(localStorage.getItem("currentLocale")) : "en-US"
  const PERCENTAGE = "percentage"

  const isHideTransactionId = Boolean(env.hideTransactionId())

  const { forApplePayGooglePayOrOtherDigitalWalletsUseTheLastFourDigitsOfTheDeviceAccountNumberOrVirtualAccountNumberThisNumberIsDifferentFromYourPhysicalCard, noReceiptsAvailableOnSelectedDateWithTheValuesYouEntered } = t("description")

  useEffect(() => {
    if (env.getReceiptUICountry() !== undefined) {
      setCountry(countryName)
      setCountryId(id)
    }
  }, [id, countryName]);

  const handleExpiryDate = date => {
    console.log("handleExpiryDate = ", date)
    let expMonth = ''
    let expYear = ''
    if (date && date.month) {
      expMonth = date.month
    }
    if (date && date.year) {
      expYear = date.year
    }
    console.log(date, expMonth)
    setCardExpirationMonth(expMonth)
    setCardExpirationYear(expYear)
    setExpiryDate(date);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleExpiryTooltipClose = () => {
    setExpiryTooltipOpen(false);
  };
  const handleTransactionIdTooltipClose = () => {
    setTransactionIdTooltipOpen(false);
  };
  const handleTransactionIdTooltipOpen = () => {
    setTransactionIdTooltipOpen(true);
  };
  const handleAmountTooltipClose = () => {
    setAmountTooltipOpen(false);
  };
  const handleAmountTooltipOpen = () => {
    setAmountTooltipOpen(true);
  };
  const handleExpiryTooltipOpen = () => {
    setExpiryTooltipOpen(true);
  };
  const handleDateChange = (date) => {
    setDateTime(date);
  };

  useEffect(() => {
    setCountry(props.searchParams.get('country_name') ? props.searchParams.get('country_name') : "")
    setCountryId(props.searchParams.get('country_id') ? props.searchParams.get('country_id') : "")
    setLast4CC(props.searchParams.get('last4') ? props.searchParams.get('last4') : "")
    if(props.searchParams.get('date')) {
      setDateTime(props.searchParams.get('date'))
    }
    setAmount(props.searchParams.get('amount') ? props.searchParams.get('amount') : "")
    setTransactionId(props.searchParams.get('transaction_id') ? props.searchParams.get('transaction_id') : "")
    setCardExpirationMonth(props.searchParams.get('exp_month'))
    setCardExpirationYear(props.searchParams.get('exp_year'))
    setExpiryDate({ "month": props.searchParams.get('exp_month'), "year": props.searchParams.get('exp_year') })
    fetchReceipts(props.searchParams.get('last4'), props.searchParams.get('date'), props.searchParams.get('exp_month'),
      props.searchParams.get('exp_year'), props.searchParams.get('country_name'), props.searchParams.get('country_id'),
      props.searchParams.get('amount'), props.searchParams.get('transaction_id'));
    if (props.searchParams.get('amount')) {
      setShowAmount(true)
      !isHideTransactionId && setShowTransactionId(true)
    }
    if (props.searchParams.get('transaction_id')) {
      !isHideTransactionId && setShowTransactionId(true)
    }
  }, []);

  const fetchReceipts = async (last4CC1, dateTime1, cardExpirationMonth1, cardExpirationYear1, country, countryId, amount, transactionId, action) => {
    let cartId = action === 'findAction' ? "" : props.searchParams.get('id')
    let date = moment(dateTime1).format("YYYY-MM-DD")
    let timeZone = dateTime1 === null ? moment(new Date()).format("Z") : moment(dateTime1).format("Z")
    console.log("fetch receipts api dateTime: " + JSON.stringify(dateTime1))
    const transactionIdParam = !isHideTransactionId ? `&transaction_id=${transactionId}` : ""
    const apiEndPoint = `${env.getTenantBackendBaseUrl()}/terminal_customer/receipts?date=${date}&last4=${last4CC1}&exp_month=${cardExpirationMonth1}&exp_year=${cardExpirationYear1}&timezone=${encodeURIComponent(timeZone)}&amount=${amount}${transactionIdParam}&version=2&country_id=${countryId}&id=${cartId}`
    console.log("fetch receipts api endpoint: " + apiEndPoint)
    const response = await fetch(`${apiEndPoint}`, {
      method: "GET",
    })
    console.log("fetch receipts api resp: " + JSON.stringify(response))
    let json = await response.json()
    console.log("fetch receipts api json: " + JSON.stringify(json))
    console.log("fetch receipts api receipts: " + JSON.stringify(json.receipts))

    // calculate receipt values
    let receipts = json.receipts
    if (receipts && receipts.length === 0) {
      errorMessage(amount, transactionId, cardExpirationMonth1, cardExpirationYear1, cartId);
      setShowNoReceiptFound(!(Array.isArray(receipts) && receipts.length > 0))
      setReceipts([]);
    }
    Array.isArray(receipts) && receipts.forEach((receipt) => {
      let subtotal = 0,
        totalDisc = 0,
        totalTaxes = 0,
        totalLoyalty = 0,
        loyaltyDetails = receipt.loyalty_details && JSON.parse(receipt.loyalty_details),
        paymentPartnerDiscountDetails = receipt.discount_details && JSON.parse(receipt.discount_details),
        discountDetails = null, taxTypes = {}, isGrossAmountPresent = false
      if (receipt && Array.isArray(receipt.items)) {
        isGrossAmountPresent = receipt.items.some((item) => item.grossAmount)
        receipt.items.forEach((item) => {
          subtotal += item.quantity * (item.base_price + item.crv)
          if (Array.isArray(item.tax) && item.tax.length > 0) {
            if (item.tax.every(val => typeof val === 'number')) {
              totalTaxes += item.tax.reduce((acc, val) => acc + val, 0);
            } else {
              const itemTaxes = item.tax[0];
              if (typeof itemTaxes === "object") {
                Object.keys(itemTaxes).forEach(key => {
                  const taxValue = itemTaxes[key];
                  if (!taxTypes[key]) {
                    taxTypes[key] = { value: 0, name: null };
                  }
                  const totalTaxAmount = taxValue.reduce((sum, val) => sum + val, 0)
                  taxTypes[key].value += totalTaxAmount
                  taxTypes[key].name = findTaxTypeName(item.taxes, key);
                  totalTaxes += totalTaxAmount
                });
              }
            }
          }

          item.discount.forEach((discount) => {
            totalDisc += discount
          })
          if (item.loyalty && item.loyalty.length > 0) {
            item.loyalty.forEach((loyalty) => {
              totalLoyalty += loyalty
            })
          }
        })
        if (loyaltyDetails || paymentPartnerDiscountDetails) {
          discountDetails = {}
          let loyaltyAndDiscountDetailsItems = []
          if (loyaltyDetails && Array.isArray(loyaltyDetails.items) && loyaltyDetails.items.length > 0) {
            let itemsInLoyaltyDetails = JSON.parse(JSON.stringify(loyaltyDetails.items))
            loyaltyAndDiscountDetailsItems = [...loyaltyAndDiscountDetailsItems, ...itemsInLoyaltyDetails]
          }
          if (paymentPartnerDiscountDetails && Array.isArray(paymentPartnerDiscountDetails.items) && paymentPartnerDiscountDetails.items.length > 0) {
            let itemsInPaymentPartnerDiscountDetailItems = JSON.parse(JSON.stringify(paymentPartnerDiscountDetails.items))
            itemsInPaymentPartnerDiscountDetailItems.forEach(itemsInPaymentPartnerDiscountDetail => {
              totalDisc -= itemsInPaymentPartnerDiscountDetail.discounts_applied
            })
            loyaltyAndDiscountDetailsItems = [...loyaltyAndDiscountDetailsItems, ...itemsInPaymentPartnerDiscountDetailItems]
          }
          if (loyaltyAndDiscountDetailsItems.length > 0) {
            loyaltyAndDiscountDetailsItems.forEach(item => {
              if (item.discounts) {
                if (Array.isArray(item.discounts) && item.discounts.length > 0) {
                  item.discounts.forEach(di => {
                    if (Object.prototype.hasOwnProperty.call(discountDetails, di.description)) {
                      discountDetails[di.description] += di.amount
                    } else {
                      discountDetails[di.description] = di.amount
                    }
                  })
                }
              } else if (item.discounts_applied) {
                if (Object.prototype.hasOwnProperty.call(discountDetails, item.discount_description)) {
                  discountDetails[item.discount_description] += item.discounts_applied
                } else {
                  discountDetails[item.discount_description] = item.discounts_applied
                }
              }
            })
          } else if (loyaltyDetails.discountAmount && loyaltyDetails.discountAmount > 0) {
            let description = loyaltyDetails.description
            if (loyaltyDetails.description && Array.isArray(loyaltyDetails.description) && loyaltyDetails.description.length > 0) {
              description = loyaltyDetails.description.join(",")
            }
            discountDetails[description] = loyaltyDetails.discountAmount
          }
        }
      }
      let totalAmount = receipt.amount
      if ((receipt.receiptFormat && receipt.receiptFormat === EURO) || (!receipt.isGrossAmountPresent && receipt.taxes === "inclusive")) {
        subtotal = subtotal + totalTaxes
        totalAmount = totalAmount - totalTaxes
      }
      receipt.subtotal = subtotal
      receipt.totalTaxes = totalTaxes
      receipt.taxTypes = taxTypes
      receipt.totalDisc = totalDisc
      receipt.totalLoyalty = totalLoyalty
      receipt.discountDetails = discountDetails
      receipt.totalPaid = totalAmount
      receipt.isGrossAmountPresent = isGrossAmountPresent
    })
    console.log("receipts calced: ", receipts)
    if (Array.isArray(receipts) && receipts.length === 1) {
      setReceipts(receipts)
      setCountry(receipts[0].country_name)
      setCountryId(receipts[0].country_id)
    }
    if (Array.isArray(receipts) && receipts.length > 1) {
      setTimePopUp(true)
      setReceiptData(receipts)
    }
    if (action === 'findAction') {
      props.setSearchParams({
        ["hl"]: currentLocale, ["date"]: date, ["last4"]: last4CC1, ["exp_month"]: cardExpirationMonth1, ["exp_year"]: cardExpirationYear1,
        ["timezone"]: encodeURIComponent(timeZone), ["amount"]: amount, ...(!isHideTransactionId && { ["transaction_id"]: transactionId }), ["version"]: 2, ["country_id"]: countryId,
        ["country_name"]: country
      })
    }
  }

  const findTaxTypeName = (taxes, code) => {
    for (const tax of taxes) {
      if (tax.hasOwnProperty(code)) {
        return tax.name;
      }
    }
    return null;
  };

  const errorMessage = (amount, transactionId, cardExpirationMonth, cardExpirationYear, cartId) => {
    if (!cartId) {
      let filterFeilds = [t('lastFour')]
      if (cardExpirationMonth && cardExpirationYear) {
        filterFeilds.push(t('expirationDateLowerCase'))
      }
      if (transactionId) {
        filterFeilds.push(t('transactionIdLowerCase'))
      }
      if (amount) {
        filterFeilds.push(t('amount'))
      }
      if (cartId) {
        filterFeilds = []
        filterFeilds.push()
      }
      setInputValues(`${filterFeilds.join(', ').replace(/, ([^,]*)$/, ` ${t('and')} $1`)}`)
      setSelectedDate(`${moment(dateTime).format("MMM DD, YYYY")}`)
      setNoReceiptFoundMessage(t('noReceiptsAvailableOnSelectedDateWithTheValuesYouEntered'))
    }
    else {
      setNoReceiptFoundMessage(`${t('noReceiptsAvailableWithTheCartId')} ${cartId}.`)
    }

  }

  const getReceipts = async () => {
    setTime(null)
    props.searchParams.delete('time')
    setShowNoReceiptFound(false)
    setLast4Error("");
    setDateError("");
    setExpiryDateError("")
    let isVaidationError = false
    // Validate the last 4 digits
    if (!transactionId) {
      if (last4CC.length !== 4 || !/^\d+$/.test(last4CC)) {
        setLast4Error(t('pleaseEnterTheLastFourDigitsOfTheCard'));
        isVaidationError = true
      }
    }
    // Validate the selected date
    if (!dateTime) {
      setDateError(t('pleaseEnterTheDateOfYourVisit'));
      isVaidationError = true
    }
    if (cardExpirationMonth || cardExpirationYear) {
      const expiryDate = `${cardExpirationMonth}/${cardExpirationYear}`
      const isValidExpiryDate = expiryDate.match(/^(\d{2})\/(\d{2})/);
      if (!isValidExpiryDate) {
        setExpiryDateError(t('pleaseEnterValidExpirationDate'))
        isVaidationError = true
      }
    }
    if (isVaidationError) {
      return
    }
    setIsLoading(true);
    try {
      await fetchReceipts(last4CC, dateTime, cardExpirationMonth, cardExpirationYear, country, countryId, amount, transactionId, 'findAction');
    }
    catch (error) {
      console.error("Error in getReceipts -", error);
    } finally {
      setIsLoading(false);
    }
  };

  let feedbackEmailSubject = "Feedback for card receipt"
  if (last4CC) {
    feedbackEmailSubject += " of last4 " + last4CC
  }
  if (dateTime instanceof Date && !isNaN(dateTime)) {
    const formattedDateTime = format(dateTime, "MM-dd-yyyy hh:mmaaaa OOOO");
    feedbackEmailSubject += " at " + formattedDateTime;
  }
  let emailString = `mailto:support@getzippin.com?subject=${feedbackEmailSubject}`

  const handleDontHaveTransactionId = () => {
    setShowTransactionId(true)
  }

  const handleDontHaveAmount = () => {
    setShowAmount(true)
  }

  const WhiteTextField = withStyles({
    root: {
      '& .MuiInput-underline:before': {
        borderBottomColor: '#fff8', // Semi-transparent underline
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: '#fff', // Solid underline on hover
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fff', // Solid underline on focus
      },
    },
  })(TextField);

  const Last4TextField = withStyles({
    root: {
      '& .MuiOutlinedInput-input': {
        padding: '2px'
      },
    },
  })(TextField);


  const handleLast4CC = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setLast4CC(event.target.value);
    }
  }

  const handleAmount = (event) => {
    const regex = /^[0-9]*\.?[0-9]*$/
    if (event.target.value === "" || regex.test(event.target.value)) {
      setAmount(event.target.value);
    }
  }

  const handleBack = () => {
    props.setShowReceipt(false)
    props.setSearchParams({["hl"]: currentLocale})
  }


  const TextFieldComponent = (props) => <Last4TextField
    onClick={(e) => setOpenDatePicker(true)} {...props}
    variant="outlined" />;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container xs={12} md={12} style={{ fontFamily: env.getReceiptUIType() === "brightline" ? "Futura PT Bold" : 'Duplet', justifyContent: 'center', marginBottom: '10px', position: 'relative' }}>
        <Grid container className={classes.container} style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%' }} direction="row"  >
          <Grid style={{ alignSelf: 'center', justifyContent: isMobile ? isRTL(currentLocale) ? 'right' : 'left' : 'flex-end', marginRight: 4, paddingTop: isMobile ? 0 : 8 }} container xs={8} md={2} sm={5} lg={2}>
            <Grid style={{ whiteSpace: 'nowrap', marginLeft: '4px' }} item >{t('storeLocation')}
            </Grid>
          </Grid>
          <Grid item xs={8} md={2} sm={5} lg={2} style={{ marginTop: isMobile ? '6px' : '0px', marginLeft: 10, marginRight: 10 }} >
            <WhiteTextField
              className={classes.textFieldBackGroundColor}
              value={country}
              style={{ width: '100%', marginTop: isMobile ? '10px' : 0 }}
              variant='standard'
              inputProps={
                {
                  readOnly: true,
                  style: { height: 30, paddingRight: 16, paddingLeft: 16 },
                  className: [classes.textValueFont, classes.fontFamily]
                }
              }
            />
          </Grid>
        </Grid>


        <Grid container className={classes.container} style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%' }} direction="row"  >
          <Grid style={{ alignSelf: 'center', justifyContent: isMobile ? isRTL(currentLocale) ? 'right' : 'left' : 'flex-end', marginRight: 4, paddingTop: isMobile ? 0 : 8 }} container xs={8} md={2} sm={5} lg={2}>
            <Grid style={{ whiteSpace: 'nowrap', marginLeft: '4px' }} item >{t('dateOfPurchase')}
            </Grid>
          </Grid>
          <Grid item xs={8} md={2} sm={5} lg={2} style={{ marginTop: isMobile ? '6px' : '0px', marginLeft: 10, marginRight: 10 }} >
            <KeyboardDatePicker
              open={openDatePicker}
              onOpen={() => setOpenDatePicker(true)}
              onClose={() => setOpenDatePicker(false)}
              margin="normal"
              format={"MMM dd, yyyy"}
              id="date-picker-dialog"
              InputProps={{
                style: {
                  disableUnderline: true,
                  height: 40
                },
                className: [classes.textValueFont, classes.fontFamily]
              }}
              value={dateTime}
              defaultValue={defaultDateTime}
              maxDate={new Date()}
              minDate="2018/01/01"
              TextFieldComponent={TextFieldComponent}
              style={{ width: '99%' }}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </Grid>
        {dateError && <div className="error-message" style={{ color: "red", paddingTop: 10 }}>{dateError}</div>}
        <Grid container className={classes.container} style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%' }} direction="row"  >
          <Grid style={{ alignSelf: 'center', justifyContent: isMobile ? isRTL(currentLocale) ? 'right' : 'left' : 'flex-end', marginRight: 4, paddingTop: isMobile ? 0 : 8 }} container xs={8} md={2} sm={5} lg={2}>
            <Grid style={{ whiteSpace: 'nowrap', marginLeft: '4px' }} item >{t('lastFourDigitsOfTheCard')}
              <Tooltip
                enterDelay={200}
                leaveDelay={200}
                enterTouchDelay={0}
                leaveTouchDelay={15000}
                interactive
                arrow={true}
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.fullOpacity,
                  arrow: classes.arrow
                }}
                open={open}
                onOpen={handleTooltipOpen}
                onClose={handleTooltipClose}
                title={
                  <div className={env.getReceiptUIType() === "brightline" && "brightline-font"}>
                    <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                      <CloseIcon onClick={handleTooltipClose} style={{ cursor: 'pointer', color: 'grey', width: 20 }} />
                    </div>
                    <p style={{ color: '#000' }}> {t('ifYouShoppedUsingAPhysicalCardEnterTheLastFourDigitsOfTheCard')} </p>
                    <p style={{ color: '#000' }}>
                      <Trans i18nKey={forApplePayGooglePayOrOtherDigitalWalletsUseTheLastFourDigitsOfTheDeviceAccountNumberOrVirtualAccountNumberThisNumberIsDifferentFromYourPhysicalCard}
                        values={{ applePay: `${t('applePay')}`, googlePay: `${t('googlePay')}` }}
                        components={{
                          applePay: <a rel='noopener noreferrer' href='https://support.apple.com/en-us/HT212779' target='_blank' style={{ color: '#0000FF' }} />,
                          googlePay: <a rel='noopener noreferrer' href='https://support.google.com/pay/answer/7643925' target='_blank' style={{ color: '#0000FF' }} />
                        }} />
                    </p>
                  </div>
                }
              >
                <IconButton variant="contained" style={{ padding: 0, marginTop: -3, paddingLeft: 4 }}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container xs={8} md={2} sm={5} lg={2} direction="row">
            <Grid item md={12} lg={12} sm={12} xs={12} style={{ marginTop: isMobile ? '6px' : '0px' }}  >
              <Textfield
                value={last4CC}
                type="text"
                pattern="[0-9]*"
                style={{ backgroundColor: 'white' }}
                placeholder={t('enterLastFour')}
                maxLength={4}
                onChange={(event) => handleLast4CC(event)}
                autoFocus
                variant="outlined"
                inputProps={
                  {
                    style: { height: 30 },
                    className: [classes.textValueFont, classes.fontFamily]
                  }
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {last4Error && (
          <div className="error-message" style={{ paddingTop: 10, color: "red" }}>{last4Error}</div>
        )}
        <Grid container className={classes.container} style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%' }} direction="row">
          <Grid container style={{ alignSelf: 'center', justifyContent: isMobile ? isRTL(currentLocale) ? 'right' : 'left' : 'flex-end', marginRight: 4, paddingBottom: isMobile ? '0px' : '10px' }} xs={8} md={2} sm={5} lg={2}>
            <Grid item style={{ whiteSpace: 'nowrap', marginLeft: '3px' }}>{t('expirationDate')}
              <Tooltip
                enterDelay={200}
                leaveDelay={200}
                enterTouchDelay={0}
                leaveTouchDelay={15000}
                interactive
                arrow={true}
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.fullOpacity,
                  arrow: classes.arrow
                }}
                open={expiryTooltipOpen}
                onOpen={handleExpiryTooltipOpen}
                onClose={handleExpiryTooltipClose}
                title={
                  <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                    <CloseIcon onClick={handleExpiryTooltipClose} style={{ cursor: 'pointer', color: 'grey', width: 20 }} />
                    <p style={{ color: '#000' }}>{t('expirationDateIsRequiredForCreditDebitAndPrepaidCards')} </p>
                  </div>
                }
              >
                <IconButton variant="contained" style={{ padding: 0, paddingLeft: 4, marginTop: -3 }}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container xs={8} md={2} sm={5} lg={2} direction="row">
            <Grid item md={12} lg={12} sm={12} xs={12} >
              <ExpiryDateInput
                label="Expiry Date"
                onDateChange={date => handleExpiryDate(date)}
                value={expiryDate}
                disabled={false}
                onBlur={date => handleExpiryDate(date)}
              />
            </Grid>
            <Grid item onClick={() => isHideTransactionId ? handleDontHaveAmount() : handleDontHaveTransactionId()} style={{ color: '#33ACFF', cursor: 'pointer', paddingTop: 5, whiteSpace: isMobile ? '' : 'nowrap' }}>{t('dontHaveExpirationDate')}</Grid>
          </Grid>
        </Grid>
        {expiryDateError && <div className="error-message" style={{ color: "red", paddingTop: 10 }}>{expiryDateError}</div>}
        {showTransactionId &&
          <Grid container className={classes.container} style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%' }} direction="row">
            <Grid container style={{ alignSelf: 'center', justifyContent: isMobile ? isRTL(currentLocale) ? 'right' : 'left' : 'flex-end', marginRight: 4, paddingBottom: isMobile ? '0px' : '10px' }} xs={8} md={2} sm={5} lg={2}>
              <Grid item style={{ whiteSpace: 'nowrap', marginLeft: '4px', marginBottom: 10 }}>{t('transactionId')}
                <Tooltip
                  enterDelay={200}
                  leaveDelay={200}
                  enterTouchDelay={0}
                  leaveTouchDelay={15000}
                  interactive
                  arrow={true}
                  classes={{
                    tooltip: classes.tooltip,
                    popper: classes.fullOpacity,
                    arrow: classes.arrow
                  }}
                  open={transactionIdTooltipOpen}
                  onOpen={handleTransactionIdTooltipOpen}
                  onClose={handleTransactionIdTooltipClose}
                  title={
                    <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                      <CloseIcon onClick={handleTransactionIdTooltipClose} style={{ cursor: 'pointer', color: 'grey', width: 20 }} />
                      <p style={{ color: '#000' }}>{t('yourTransactionIdIsLocatedInTheListOfTransactionsProvidedByYourCreditOrDebitCardProviderToLookUpYourReceiptEnterTheSixDigitAlphanumericIdThatFollowsTheStar')}
                        <br />{t('example')} ZIPPIN* <b>22ABC3</b> DALLAS</p>
                    </div>
                  }
                >
                  <IconButton variant="contained" style={{ padding: 0, paddingLeft: 4, marginTop: -3 }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container xs={8} md={2} sm={5} lg={2} direction="row">
              <Grid item md={12} lg={12} sm={12} xs={12} style={{ marginTop: isMobile ? '6px' : '0px' }}  >
                <Textfield
                  autoFocus
                  style={{ backgroundColor: 'white' }}
                  maxLength={6}
                  value={transactionId}
                  placeholder={t('enterTransactionId')}
                  onChange={(event) => setTransactionId(event.target.value)}
                  inputProps={
                    {
                      style: { height: 30 },
                      className: [classes.textValueFont, classes.fontFamily]
                    }
                  }
                />
              </Grid>
              <Grid item onClick={() => handleDontHaveAmount()} style={{ color: '#33ACFF', cursor: 'pointer', whiteSpace: isMobile ? '' : 'nowrap', paddingTop: 5 }}>{t('dontHaveTransactionId')}</Grid></Grid>
          </Grid>}
        {showAmount &&
          <Grid container className={classes.container} style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%' }} direction="row">
            <Grid container style={{ alignSelf: 'center', justifyContent: isMobile ? isRTL(currentLocale) ? 'right' : 'left' : 'flex-end', marginRight: 4, paddingBottom: isMobile ? '0px' : '10px' }} xs={8} md={2} sm={5} lg={2}>
              <Grid item style={{ marginTop: 10, marginLeft: '4px' }}>{t('amount')}</Grid>
              <Tooltip
                enterDelay={200}
                leaveDelay={200}
                enterTouchDelay={0}
                leaveTouchDelay={15000}
                interactive
                arrow={true}
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.fullOpacity,
                  arrow: classes.arrow
                }}
                open={amountToolTipOpen}
                onOpen={handleAmountTooltipOpen}
                onClose={handleAmountTooltipClose}
                title={
                  <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                    <CloseIcon onClick={handleAmountTooltipClose} style={{ cursor: 'pointer', color: 'grey', width: 20 }} />
                    <p style={{ color: '#000' }}>{t('availableInYourCardStatementOrAppEnterAmountInTheLocalCurrencyOfWhereYouShopped')}<br />{t('exampleIfTheLocalCurrencyIsUsdAndYourPurchaseAmountIsTenEnterTen')}
                    </p>
                  </div>
                }
              >
                <IconButton variant="contained" style={{ padding: 0, paddingLeft: 4, marginTop: -3, paddingTop: '12px' }}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid xs={8} md={2} sm={5} lg={2} item style={{ marginTop: isMobile ? '6px' : '0px' }} >
              <Textfield
                value={amount}
                style={{ backgroundColor: 'white' }}
                autoFocus
                placeholder={t('enterAmount')}
                onChange={(event) => handleAmount(event)}
                inputProps={
                  {
                    style: { height: 30 },
                    className: [classes.textValueFont, classes.fontFamily]
                  }
                }
              />
            </Grid></Grid>}
        <Grid container className={classes.container} style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%' }} justify="center" alignItems="center" direction="row">
          <Grid container style={{ justifyContent: isMobile ? 'right' : 'flex-end', marginRight: 4 }} xs={8} md={2} sm={5} lg={2}>
            <Grid style={{ width: isMobile && '100%' }} className={classes.element1ToHide} item >
              <Button
                variant="contained"
                className={[classes.button, classes.fontFamily, classes.buttonItem]}
                onClick={() => getReceipts()}
                disabled={isLoading}
                id="elementToShowInMoble"
              >
                {isLoading ? (
                  <CircularProgress size={24} /> // Display a loader when isLoading is true
                ) : t('findMyReceipt')}
              </Button>
            </Grid>
            {!env.getReceiptUICountry() &&
              <Grid item style={{ marginRight: !isMobile && '8px', marginLeft: !isMobile && '8px', width: isMobile && '100%' }}>  <Button
                variant="outlined"
                color="primary"
                className={[classes.fontFamily, classes.buttonItem]}
                onClick={() => handleBack()}
                disabled={isLoading}
              >
                {t('back')}
              </Button>
              </Grid>}
          </Grid>
          <Grid className={classes.element2ToHide} xs={8} md={2} sm={5} lg={2} item >
            <Button
              variant="contained"
              className={[classes.button, classes.fontFamily, classes.buttonItem]}
              onClick={() => getReceipts()}
              disabled={isLoading}
              id="elementToHideInMobile"
            >
              {isLoading ? (
                <CircularProgress size={24} /> // Display a loader when isLoading is true
              ) : t('findMyReceipt')}
            </Button>
          </Grid>
        </Grid>
        <div>
          {Array.isArray(receipts) && receipts.length > 0 ? (
            <div>
              <ReceiptsList receipts={receipts} />
            </div>
          ) : (
            <div style={{ textAlign: "center", marginTop: 10, marginBottom: '5px' }}>
              {showNoReceiptFound && selectedDate && inputValues &&
                <Trans i18nKey={noReceiptsAvailableOnSelectedDateWithTheValuesYouEntered}
                  values={{ selectedDate: selectedDate, values: inputValues }} />
              }
              {showNoReceiptFound && !selectedDate && !inputValues
                && noReceiptFoundMessage}
            </div>
          )}
        </div>
        <Grid container style={{ justifyContent: 'center' }} className={classes.fontFamily} >
          <ZippinApp email={emailString} />
        </Grid>
        {showTimePopUp &&
          <TimePopUp dateTime={dateTime} receipts={receipts} setTimePopUp={setTimePopUp} setReceipts={setReceipts}
            showTimePopUp={showTimePopUp} receiptData={receiptData} setReceiptData={setReceiptData} setShowNoReceiptFound={setShowNoReceiptFound}
            time={time} setTime={setTime} setSearchParams={props.setSearchParams} searchParams=
            {props.searchParams} />
        }
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default withStyles(styles)(Receipts)
