import React from "react"
import { format, differenceInSeconds } from "date-fns"
import Item from "./Item"
import * as env from "./env"
import { convertBackToActualPrice, EURO } from "./common"
import moment from "moment"
import { withStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isRTL } from './common'

const styles = () => ({
  listWidth: {
    maxWidth: '1000px',
    '@media (max-width: 280px)': {
      maxWidth: '230px'
    }
  },
})


const getLoyaltyId = (memberId) => {
  let loyaltyId = null
  if (memberId && Array.isArray(memberId) && memberId.length > 0) {
    const result = memberId.map(ele => `*${ele.substr(ele.length - 4)}`)
    loyaltyId = result.join(",")
  } else {
    loyaltyId = `*${memberId.substr(memberId.length - 4)}`
  }
  return loyaltyId
}

const getLocalTimeZone = () => {
  const dateAsString = new Date().toString();
  const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
  var matches = timezone.match(/\b(\w)/g);
  var tzAbbreviations = matches.join('');
  return tzAbbreviations
}

const calcDurationTime = (receipt) => {
  let durationSecs = (receipt.entry_time && receipt.exit_time) ? differenceInSeconds(new Date(receipt.exit_time), new Date(receipt.entry_time)) : 0
  if (durationSecs > 60) {
    return Math.round(durationSecs / 60) + "m and " + (durationSecs % 60) + "s"
  } else {
    return durationSecs + "s"
  }
}

const getTotalPromotions = (item, discountDetailsForItem, currencyConfig, promotionCode) => {
  let discountsApplied = []
  let totalPromotions = 0
  let totalDiscountDetailsAmount = 0
  let discountsInItem = discountDetailsForItem ? discountDetailsForItem.discounts : null
  if (Array.isArray(discountsInItem) && discountsInItem.length > 0) {
    for (let i = 0; i < discountsInItem.length; i++) {
      let discount = discountsInItem[i]
      if (discount.amount > 0) {
        discountsApplied.push({ code: discount.description, amount: convertBackToActualPrice(discount.amount, currencyConfig, true) })
        totalDiscountDetailsAmount += discount.amount
      }
    }
  }
  if (item.discount != null) {
    totalPromotions = item.discount.reduce((totalDiscount, discount) => (totalDiscount + discount), 0)
    if (totalPromotions > 0) {
      const promoAmount = totalPromotions - totalDiscountDetailsAmount
      const totalPromoAmount = convertBackToActualPrice(promoAmount, currencyConfig, true)
      if (promoAmount > 0) {
        let promotionApplied = { code: promotionCode != null ? promotionCode : "", amount: totalPromoAmount }
        discountsApplied.push(promotionApplied)
      }
    }
  }
  return { discountsApplied }
}

const getTotalLoyalty = (item) => {
  let totalLoyalty = 0
  if (item.loyalty != null) {
    totalLoyalty = item.loyalty.reduce((totalLoyalty, loyalty) => (totalLoyalty + loyalty), 0)
  }
  return totalLoyalty
}

const RenderTaxDetails = ({ receipt, currentLocale, currencyConfig, t, isTaxInclusive }) => {
  return (
    <span style={{ float: isRTL(currentLocale) ? "left" : null }}>
      {Object.keys(receipt.taxTypes).length > 0 ?
        <>
          {Object.keys(receipt.taxTypes).map((key, i) => (
            <>
              {receipt.taxTypes[key].value > 0 &&
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "wrap"
                }}>
                  <span style={{ flex: 2 }}>{isTaxInclusive && receipt.isGrossAmountPresent && `(${t('included')} `}{receipt.taxTypes[key].name === "Sales tax" ? t('salesTax') : receipt.taxTypes[key].name}</span>
                  <span style={{ flex: 1, textAlign: "end" }}>{convertBackToActualPrice(receipt.taxTypes[key].value, currencyConfig, true)}{isTaxInclusive && ")"}</span>
                </div>
              }
            </>
          ))}
        </> :
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "wrap"
        }}>
          <span>{isTaxInclusive && receipt.isGrossAmountPresent && `(${t('included')} `}{`${receipt.defaultTaxName ? receipt.defaultTaxName === "Sales tax" ? t('salesTax') : `${receipt.defaultTaxName}` : t('taxes')}`}</span>
          <span style={{ float: isRTL(currentLocale) ? "left" : "right" }}>{convertBackToActualPrice(receipt.totalTaxes, currencyConfig, true)}{isTaxInclusive && ")"}</span>
        </div>}
    </span>
  )
}

const ReceiptsList = (props) => {
  const { receipts, showNoReceiptFound, classes } = props
  const { t, i18n } = useTranslation()
  const currentLocale = i18n.language;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  console.log("ReceiptsList render: receipts ", receipts)
  console.log("ReceiptsList render: receipts ", JSON.stringify(receipts))
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }} className={classes.listWidth}>
      <>
        <div style={{
          textAlign: "justify",
          maxWidth: 'auto',
          display: "flex",
          flexDirection: "column",
          marginLeft: 10,
          marginRight: 10
        }}>
          <p>{t('yourReceiptIsRetrievedUsingTheLastFourDigitsOfYourCreditCardWhichIsNotUniqueYouMaySeeOtherReceiptsBesidesYourOwnCheckYourCreditCardStatementToConfirmTheAmountChargedToYourCard')}</p>
        </div>
        {receipts.map((receipt) => {
          let receiptText = []
          let receiptData = null
          if (receipt.receiptText) {
            let receiptValue = receipt.receiptText ? receipt.receiptText : null
            receiptText = receiptValue ? receiptValue.split("\n") : []
            if (receiptText.length === 1) {
              receiptText = receiptValue.split("\\n")
            }
          }
          if (receipt.receiptData) {
            receiptData = JSON.parse(receipt.receiptData)
          }
          let currencyConfig = { config: receipt.currencyConfig, symbol: receipt.currencySymbol }
          let transactionProblemSubject = "Problem with transaction : " + receipt.cart_id
          let transactionBody = "{Add your questions and requests here}\n\n"
          transactionBody += "#####################" + "\n" + "TRANSACTION DETAILS" + "\n" + "#####################" + "\n"
            + "Transaction # : " + receipt.cart_id + "\n" + "Store : " + receipt.name + "\n" + "Last 4 : " + receipt.last4 + "\n" + "Date : " + format(new Date(receipt.entry_time), "MM-dd-yyyy hh:mmaaaa OOOO");
          transactionBody = encodeURIComponent(transactionBody);
          let transactionEmail = `mailto:support@getzippin.com`
          if (env.getReceiptUIType() === "almeera") {
            transactionEmail += `,customerservice@almeera.com.qa`
          }
          if (env.getReceiptUIType() === "SSPDUBLIN") {
            transactionEmail = `mailto:customer.care@ssp.uk.com`
          }
          transactionEmail += `?subject=${transactionProblemSubject}&body=${transactionBody}`
          const paymentMethods = receipt.paymentMethods ? JSON.parse(receipt.paymentMethods) : []
          let refundTypesArray = receipt.refundMethods ? JSON.parse(receipt.refundMethods) : []
          const loyalty_details = receipt.loyalty_details ? JSON.parse(receipt.loyalty_details) : {}
          refundTypesArray.forEach((item) => {
            if (item.refund_type && item.refund_type === "card" && receipt.last4 && receipt.brand) {
              item.name = receipt.brand + " *" + receipt.last4
            }
            if (
              item.refund_type &&
              item.refund_type === "stored_cash" &&
              loyalty_details &&
              item.id
            ) {
              item.name = item.name + " " + getLoyaltyId(item.id)
            }
          })
          let refundMethods = []
          refundTypesArray.reduce(function (res, value) {
            if (!res[value.name]) {
              res[value.name] = { refund_type: value.refund_type, name: value.name, amount: 0 };
              refundMethods.push(res[value.name])
            }
            res[value.name].amount += value.amount;
            return res;
          }, {});
          return (
            <div
              key={receipt.cart_id}
              style={{ boxShadow: "0px 1px 10px rgba(0,0,0,0.08)", marginTop: 20, padding: 10, marginLeft: isMobile ? 0 : "220px", marginRight: isMobile ? 0 : "220px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  paddingBottom: 10,
                }}
              >
                <span style={{ alignSelf: "flex-start", flexGrow: 1 }}>
                  <span style={{ marginRight: 5, marginLeft: isRTL(currentLocale) ? 5 : 0 }}>&#128197;</span>
                  {receipt.entry_time && moment(receipt.entry_time.replace('Z', '')).format("lll")} {getLocalTimeZone()}
                </span>
                <span style={{ alignSelf: "flex-end" }}>
                  <span style={{ marginRight: 5, marginLeft: isRTL(currentLocale) ? 5 : 0 }}>🏬</span>
                  {receipt.name}
                </span>
              </div>
              {receipt.isCartUnderProcessing === 1 &&
                <div style={{ textAlign: "center" }}>{t('thankYouForShoppingYourReceiptIsPendingPleaseCheckBackLater')}</div>
              }
              {(receipt.isCartUnderProcessing === 0 && (!receipt.items || (Array.isArray(receipt.items) && receipt.items.length === 0))) &&
                <div style={{ fontWeight: "bold", textAlign: "center" }}>{t('noItemsPurchased')}</div>
              }
              {Array.isArray(receipt.items) &&
                receipt.items.map((item) => {
                  let discountDetails = receipt.discount_details ? JSON.parse(receipt.discount_details) : null
                  const discountDetailsForItem = discountDetails && Array.isArray(discountDetails.items) && discountDetails.items.length > 0 ? discountDetails.items.find((discountDetail) => discountDetail.sku === item.sku) : null
                  let { discountsApplied } = getTotalPromotions(item, discountDetailsForItem, currencyConfig, receipt.promoCode)
                  let totalLoyalty = getTotalLoyalty(item)
                  if (totalLoyalty > 0) {
                    totalLoyalty = convertBackToActualPrice(totalLoyalty, currencyConfig, true)
                    if (typeof item.loyaltyDescription === "string" && item.loyaltyDescription.trim().length > 0) {
                      let loyaltyApplied = { code: item.loyaltyDescription, amount: totalLoyalty }
                      discountsApplied.push(loyaltyApplied)
                    }
                    if (Array.isArray(item.loyaltyDescription) && item.loyaltyDescription.length > 0) {
                      let loyaltyDescription = item.loyaltyDescription
                      for (let i = 0; i < loyaltyDescription.length; i++) {
                        let desc = loyaltyDescription[i]
                        if (typeof desc === 'object' && !Array.isArray(desc) && desc !== null && desc.amount > 0) {
                          discountsApplied.push({ ...desc, amount: convertBackToActualPrice(desc.amount, currencyConfig, true) })
                        } else if (typeof desc === "string" && desc.trim().length > 0) {
                          discountsApplied.push({
                            code: desc,
                            amount: totalLoyalty
                          });
                        }
                      }
                    }
                  }
                  return <Item item={item} key={item.sku} currencyConfig={currencyConfig} receipt={receipt} discountsApplied={discountsApplied} />
                })}
              {receipt.isCartUnderProcessing === 0 && Array.isArray(receipt.items) && receipt.items.length > 0 &&
                <div
                  style={{
                    maxWidth: 300,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: isRTL(currentLocale) ? "flex-end" : "flex-start",
                    marginRight: isRTL(currentLocale) ? "auto" : null,
                    marginLeft: currentLocale !== "ar-AA" ? "auto" : null,
                  }}
                >
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    fontWeight: "bold"
                  }}>
                    <span style={{ flex: 2 }}>{t('subtotal')}</span>
                    <span style={{ flex: 1, textAlign: "end" }}>{convertBackToActualPrice(receipt.subtotal, currencyConfig, true)}</span>
                  </div>
                  {receipt.totalDisc > 0 && (
                    <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}>
                      <span style={{ flex: 2 }}>{env.getReceiptUIType() === 'AMEX' ? t('amexDiscount') : t('discount')} ({receipt.promoCode})</span>
                      <span style={{ flex: 1, textAlign: "end" }}>-{convertBackToActualPrice(receipt.totalDisc, currencyConfig, true)}</span>
                    </div>
                  )}
                  {receipt.discountDetails && Object.keys(receipt.discountDetails).length > 0 && (
                    <>
                      {receipt && receipt.discountDetails && Object.keys(receipt.discountDetails).map((key, i) => (
                        <div style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          flexWrap: "wrap"
                        }}>
                          <span style={{ flex: 2 }}>{`${t('discount')} ${key ? `(${key})` : ''}`}</span>
                          <span style={{ flex: 1, textAlign: "end" }}>-{convertBackToActualPrice(receipt.discountDetails[key], currencyConfig, true)}</span>
                        </div>
                      ))}
                    </>
                  )}
                  {(receipt.taxes === "exclusive" || receipt.receiptFormat === EURO || (!receipt.isGrossAmountPresent && receipt.taxes === "inclusive")) &&
                    <RenderTaxDetails receipt={receipt} currentLocale={currentLocale} currencyConfig={currencyConfig} t={t} isTaxInclusive={false} />
                  }
                  <span style={{ float: isRTL(currentLocale) ? "left" : null }}>
                    {receipt.totalGratuity !== null &&
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        flexWrap: "wrap"
                      }}>
                        <span style={{ flex: 2 }}>{t('gratuity')}</span>
                        <span style={{ flex: 1, textAlign: "end" }}>{convertBackToActualPrice(receipt.totalGratuity, currencyConfig, true)}</span>
                      </div>
                    }
                  </span>
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    fontWeight: "bold"
                  }}>
                    <span style={{ flex: 2 }}>{((receipt.receiptFormat && receipt.receiptFormat === EURO) || (!receipt.isGrossAmountPresent && receipt.taxes === "inclusive")) ? t('netTotal') : t('total')}</span>{" "}
                    <span style={{ flex: 1, textAlign: "end" }}>{convertBackToActualPrice(receipt.totalPaid, currencyConfig, true)}</span>
                  </div>
                  {receipt.taxes === "inclusive" && receipt.isGrossAmountPresent &&
                    <RenderTaxDetails receipt={receipt} currentLocale={currentLocale} currencyConfig={currencyConfig} t={t} isTaxInclusive={true} />
                  }
                  {Object.keys(paymentMethods).map((key, i) => (
                    <>
                      {paymentMethods[i].payment_type === null ? '' :
                        <div style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          flexWrap: "wrap"
                        }}>
                          <span style={{ flex: 2 }}>{(paymentMethods[i].payment_type === "card") ? (receipt.brand === null ? "Card" : receipt.brand) + " *" + receipt.last4 : ((loyalty_details && paymentMethods[i].id) ? paymentMethods[i].name + getLoyaltyId(paymentMethods[i].id) : paymentMethods[i].name)}</span>{" "}
                          <span style={{ flex: 1, textAlign: "end" }}>{convertBackToActualPrice(paymentMethods[i].amount, currencyConfig, true)}</span>
                        </div>
                      }
                    </>
                  ))}
                </div>
              }
              {Array.isArray(receipt.refund_items) && receipt.refund_items.length > 0 && (
                <>
                  <p>
                    <span style={{ marginRight: isRTL(currentLocale) ? 0 : 5, marginLeft: isRTL(currentLocale) ? 10 : 0 }}>&#x1F4B8;</span>{t('refunds')}
                  </p>
                  {receipt.refund_items.map((item) => {
                    // item name isn't in refund array, find item details in items array
                    let itemWithDetails
                    receipt.items.forEach((itemDetailed) => {
                      if (item.sku === itemDetailed.sku) {
                        itemWithDetails = { ...itemDetailed }
                        itemWithDetails.quantity = item.quantity
                        itemWithDetails.amount = item.amount
                      }
                    })

                    return <Item item={itemWithDetails} key={itemWithDetails.sku} isRefund={true} currencyConfig={currencyConfig} receipt={receipt} />
                  })}
                </>
              )}
              <br />
              {(refundMethods.length > 0) && (
                <span style={{ fontWeight: "bold" }}>
                  <span style={{ marginLeft: 5, marginRight: 5 }}>{t('refundedTo')}</span>{" "}
                </span>
              )}
              <br />
              {Object.keys(refundMethods).map((key, i) => (
                <div>
                  <span style={{ marginLeft: 5, marginRight: 5 }}>{refundMethods[i].name}</span>{" "}
                  <span style={{ float: isRTL(currentLocale) ? "left" : "right" }}>{convertBackToActualPrice(refundMethods[i].amount, currencyConfig, true)}</span>
                </div>
              ))}
              {receipt.name === "Paycom 1" &&
                <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                  <span
                    style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                  >
                    {t('allSalesIncludeEightPointSixTwoFivePercentLocalSalesTaxAndThirteenPointFivePercentMixedBeverageTax')}
                  </span>
                </div>
              }
              <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                <span
                  style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                >
                  <span style={{ marginRight: isRTL(currentLocale) ? 5 : 8, marginLeft: isRTL(currentLocale) ? 5 : 0 }}>&#128722;</span>
                  {t('order')} {receipt.cart_id}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                <span
                  style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                >
                  <span style={{ marginRight: isRTL(currentLocale) ? 5 : 8, marginLeft: isRTL(currentLocale) ? 5 : 0 }}>&#128205;</span>
                  {receipt.name}, {receipt.location}
                </span>
              </div>
              {receipt.isCartUnderProcessing === 0 &&
                <>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                    <span
                      style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                    >
                      <span style={{ marginRight: isRTL(currentLocale) ? 5 : 8, marginLeft: isRTL(currentLocale) ? 5 : 0 }}>&#9201;</span>
                      {t('yourVisitDuration')} {calcDurationTime(receipt)}
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                    <span
                      style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                    >
                      <span style={{ marginRight: isRTL(currentLocale) ? 5 : 8, marginLeft: isRTL(currentLocale) ? 5 : 0 }}>&#9757;</span>
                      <a href={transactionEmail}>{t('problemOrQuestionsWithThisReceipt')}</a>
                    </span>
                  </div>
                </>
              }
              {(Array.isArray(receiptText) && receiptText.length > 0 || receiptData) && (
                <>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                    <span
                      style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                    >
                      <span style={{ marginRight: 8 }}>&#129534;</span>
                      {t('authorizationReceiptTextAnAuthWasPlacedOnYourCardWhenYouEnteredTheStoreDetailsBelowYourCardIsChargedTheAmountShownAboveInTheReceipt')}
                    </span>
                  </div>
                  {receiptText && (
                    <>
                      {receiptText.map((item) => (
                        <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                          <span
                            style={{ fontWeight: 200, fontSize: 14, tabSize: 4 }}
                          >
                            &emsp;&emsp;&emsp;{item}
                          </span>
                        </div>
                      ))}
                    </>)}
                  {receiptData && (
                    <>
                      {Object.entries(receiptData).map(([key, value]) => (
                        <div style={{ marginLeft: 20, marginTop: 10 }}>
                          <span style={{ fontWeight: 200, fontSize: 14 }}>
                            {key}: {value}
                          </span>
                        </div>
                      ))}
                    </>)}
                </>
              )
              }
            </div>
          )
        })}
      </>

    </div >
  )
}

export default withStyles(styles)(ReceiptsList)
